// Generated by Framer (f318921)

import { addFonts, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {fdXnWa_Ig: {hover: true}};

const serializationHash = "framer-9gBCC"

const variantClassNames = {fdXnWa_Ig: "framer-v-1htxgnw"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "fdXnWa_Ig", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1htxgnw", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"fdXnWa_Ig"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"fdXnWa_Ig-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-9gBCC.framer-ongj4x, .framer-9gBCC .framer-ongj4x { display: block; }", ".framer-9gBCC.framer-1htxgnw { cursor: pointer; height: 115px; overflow: hidden; position: relative; width: 149px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 115
 * @framerIntrinsicWidth 149
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"XmLoP0jd8":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerGUdi2mFXZ: React.ComponentType<Props> = withCSS(Component, css, "framer-9gBCC") as typeof Component;
export default FramerGUdi2mFXZ;

FramerGUdi2mFXZ.displayName = "moving button ux";

FramerGUdi2mFXZ.defaultProps = {height: 115, width: 149};

addFonts(FramerGUdi2mFXZ, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})